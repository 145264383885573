<template>
  <div></div>
</template>

<script>
export default {
  name : "Redirector",
   mounted() {
    if (this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect)
    }    
  },
}
</script>

<style>

</style>